import {useTranslation} from "react-i18next";
import {Dialog} from '@headlessui/react'
import {Bars3Icon, XMarkIcon} from '@heroicons/react/24/outline'
import React, {useState} from "react";
import heroBackgroundImage from "../assets/images/hero_background/about_hero_bg_original7.jpg";

const Navbar = () => {
    const lngs = {
        en: {nativeName: 'English'},
        zh: {nativeName: 'Chinese'}
    }
    const {t, i18n} = useTranslation(['navbar', 'common'])
    const isChinese = i18n.language === 'zh';
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    const navigation = [
        {name: t("home"), href: '/'},
        {name: t("areas"), href: '#areas'},
        // {name: t("news"), href: '#news'},
        {name: t("contact"), href: '#contact'},
        // {name: t("services"), href: '/services'},
        // {name: t("blog"), href: '/blog'},
        // {name: t("brain_talk"), href: '/brain-talk'},
        // {name: t("publications"), href: '/publications'},
        // {name: t("structure"), href: '/structure'},
        // {name: t("people"), href: '/people'},
        // {name: t("about"), href: '/about'},
    ]

    return (
        <header className="absolute inset-x-0 top-0 z-50">
            <nav className="flex items-center justify-between p-6 xl:px-8" aria-label="Global">
                <div className="flex xl:flex-1">
{/*                    <a href="/" className="-m-1.5">
                        <span className="sr-only">{t("project_name_short")}</span>
                        <img
                            className="h-8 w-auto bg-white rounded-full p-[2px]"
                            src={process.env.PUBLIC_URL + `/logo/red_logo_without_bg_with_text.png`}
                            alt="logo"
                        />
                    </a>*/}
                </div>
                <div className="flex xl:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-[#c00000]"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
                    </button>
                </div>
                <div className="hidden xl:flex xl:gap-x-10">
                    {navigation.map((item) => (
                        <a key={item.name} href={item.href} className={`text-[16px] font-medium leading-6 text-[#c00000] ${isChinese ? 'tracking-widest' : 'tracking-wide'}`}>
                            {item.name}
                        </a>
                    ))}
                    {i18n.resolvedLanguage === "zh" &&
                        <a onClick={() => i18n.changeLanguage("en")} className="text-[16px] font-medium leading-6 text-[#c00000] tracking-wide cursor-pointer">English</a>
                    }
                    {i18n.resolvedLanguage === "en" &&
                        <a onClick={() => i18n.changeLanguage("zh")} className="text-[16px] font-medium leading-6 text-[#c00000] tracking-widest cursor-pointer">中文</a>
                    }
                </div>
                <div className="hidden xl:flex xl:flex-1 xl:justify-end">
{/*                    <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
                        Log in <span aria-hidden="true">&rarr;</span>
                    </a>*/}
                </div>
            </nav>
            <Dialog as="div" className="xl:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <div className="fixed inset-0 z-50"/>
                <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 bg-cover bg-black/10 bg-blend-multiply bg-bottom bg-no-repeat "
                              style={{backgroundImage: `url(${heroBackgroundImage})`}}
                >
                    <div className="flex items-center justify-between">
{/*                        <a href="/" className="-m-1.5 p-1.5">
                            <span className="sr-only">北京人工智能安全与治理实验室</span>
                            <img
                                className="h-8 w-auto bg-white rounded-full p-[2px]"
                                src={process.env.PUBLIC_URL + `/logo/red_logo_without_bg_with_text.png`}
                                alt="logo"
                            />
                        </a>*/}
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-[#c00000]"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6 text-[#c00000]" aria-hidden="true"/>
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                {navigation.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-[#c00000] hover:bg-white/5"
                                        onClick={() => setMobileMenuOpen(false)}
                                    >
                                        {item.name}
                                    </a>
                                ))}
                                {i18n.resolvedLanguage === "zh" &&
                                    <a onClick={() => {
                                        i18n.changeLanguage("en");
                                        setMobileMenuOpen(false);
                                    }}
                                       className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-[#c00000] hover:bg-white/5 cursor-pointer">English</a>
                                }
                                {i18n.resolvedLanguage === "en" &&
                                    <a onClick={() => {
                                        i18n.changeLanguage("zh");
                                        setMobileMenuOpen(false);
                                    }}
                                       className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-[#c00000] hover:bg-white/5 cursor-pointer">中文</a>
                                }
                            </div>
{/*                            <div className="py-6">
                                <a
                                    href="#"
                                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-white/5"
                                >
                                    Log in
                                </a>
                            </div>*/}
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    )
}

export default Navbar;